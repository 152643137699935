<template>
  <div class="teaching">
    <div class="teaching-left">
      <div class="teaching-left-bottom">
        <dv-border-box-1>
          <div class="teaching-left-bottom-box">
            <div class="teaching-class-video">
              <div class="teaching-class-video-title">小一班在线课堂</div>
              <div class="teaching-class-video-content">
                <!-- <videoPlayer :videoid="videoDataUrl" :indexs="indexs" /> -->
                <video
                  controls="controls"
                  autoplay
                  loop
                  muted
                  :src="videoDataUrl"
                ></video>
              </div>
            </div>
            <div class="teaching-class-test">
              <div class="teaching-class-test-name">
                <img
                  src="../../../../assets/img/zhSchool/class/class_img.png"
                  alt=""
                />
                <p v-if="gameAllLogData.length > 0">
                  {{ gameAllLogData[0].aigameName }}
                </p>
              </div>
              <div class="teaching-class-test-content">
                <div class="teaching-class-test-list-item person">
                  <h2 class="item-title">测试人数</h2>
                  <p class="item-num" v-if="gameAllLogData.length > 0">
                    <span>{{ gameAllLogData[0].challengeNumber }}</span
                    >人
                  </p>
                </div>
                <div class="teaching-class-test-list-item second">
                  <h2 class="item-title">测试次数</h2>
                  <p class="item-num" v-if="gameAllLogData.length > 0">
                    <span>{{ gameAllLogData[0].logSize }}</span
                    >次
                  </p>
                </div>
                <div class="teaching-class-test-list-item grade">
                  <h2 class="item-title">测试分数</h2>
                  <p class="item-num" v-if="gameAllLogData.length > 0">
                    <span class="active">{{ gameAllLogData[0].score }}</span
                    >分
                  </p>
                </div>
              </div>
            </div>
          </div>
        </dv-border-box-1>
      </div>

      <dv-border-box-1>
        <div class="teaching-left-tab">
          <div class="teaching-left-tab-title">切换课堂</div>
          <div class="teaching-left-tab-box">
            <div
              class="button-prev"
              slot="button-prev"
              @click="bindPrev"
              v-if="pageNum != 1"
            ></div>
            <div class="swiper-container">
              <div class="swiper-slide">
                <div
                  class="class-list"
                  v-for="(item, index) in classList"
                  :key="index"
                  @click="bindCutVideo(item.videoUrl, index)"
                >
                  <!-- <img :src="item.screenStyleImg" alt="" /> -->
                  <video
                    controls="controls"
                    autoplay
                    loop
                    muted
                    :src="item.videoUrl"
                  ></video>
                  <p>{{ item.name }}</p>
                </div>
              </div>
            </div>
            <!-- <swiper ref="mySwiper">
              <swiper-slide v-for="(item, index) in classList" :key="index">
                <div
                  class="class-list"
                  v-for="(item, index) in classList"
                  :key="index"
                  @click="bindCutVideo(item, index)"
                >
                  <img :src="item.screenStyleImg" alt="" />
                  <p>{{ item.name }}</p>
                </div>
              </swiper-slide>
            </swiper> -->
            <div
              class="button-next"
              slot="button-next"
              @click="biindNext"
              v-if="gameLogData.hasNextPage"
            ></div>
          </div>
        </div>
      </dv-border-box-1>
    </div>
    <div class="teaching-right">
      <div class="teaching-right-top">
        <dv-border-box-1>
          <div class="teaching-right-top-box">
            <div class="teaching-right-bottom-box-title">挑战成绩</div>
            <div class="ranking-item-name">
              <ul>
                <li>序号</li>
                <li>班级</li>
                <li>积分</li>
              </ul>
            </div>
            <div class="ranking-item-content" v-if="gameAllLogData.length > 10">
              <vue-seamless-scroll
                class="teacher-box-scroll"
                :data="gameAllLogData"
                :class-option="plaqueOption"
              >
                <ul v-for="(item, index) in gameAllLogData" :key="index">
                  <li>{{ index + 1 }}</li>
                  <li>{{ item.gradeName }}</li>
                  <li>{{ item.score }}分</li>
                </ul>
              </vue-seamless-scroll>
            </div>
            <div
              class="ranking-item-null"
              v-else-if="gameAllLogData.length <= 0"
            >
              <img
                src="../../../../assets/img/zhSchool/defalut/Pic_Ks_ZWSJ.png"
                alt=""
              />
            </div>
            <div class="ranking-item-content" v-else>
              <ul v-for="(item, index) in gameAllLogData" :key="index">
                <li>{{ index + 1 }}</li>
                <li>{{ item.schoolName }}</li>
                <li>{{ item.score }}分</li>
              </ul>
            </div>
          </div>
        </dv-border-box-1>
      </div>

      <div class="teaching-right-bottom">
        <dv-border-box-1>
          <div class="teaching-right-bottom-box">
            <div class="teaching-right-bottom-box-title">挑战成绩</div>
            <div
              class="teaching-right-bottom-box-content"
              v-if="gameAllLogData.length > 0"
            >
              <div
                class="teaching-right-bottom-box-list"
                v-for="(item, index) in gameAllLogData.slice(0, 3)"
                :key="index"
              >
                <div class="teaching-right-bottom-box-list-img">
                  <video
                    controls="controls"
                    autoplay
                    loop
                    muted
                    :src="item.videoUrl"
                  ></video>
                </div>
                <div class="teaching-right-bottom-box-list-info">
                  <div class="info-content">
                    <h2 class="info-name">{{ item.aigameName }}</h2>
                    <p class="info-num">
                      <span>测试人数</span>{{ item.challengeNumber }}人
                    </p>
                    <p class="info-second">
                      <span>测试次数</span>{{ item.logSize }}次
                    </p>
                  </div>
                  <div class="info-grade">
                    <div class="info-grade-content">
                      <span class="num">{{ item.score }}</span>
                      <span class="text">得分</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="teaching-right-bottom-box-null" v-else>
              <img
                src="../../../../assets/img/zhSchool/defalut/Pic_Ks_ZWSJ.png"
                alt=""
              />
            </div>
          </div>
        </dv-border-box-1>
      </div>
    </div>
  </div>
</template>
<script>
require("video.js/dist/video-js.css");
require("vue-video-player/src/custom-theme.css");
import vueSeamlessScroll from "vue-seamless-scroll";
//import videoPlayer from "@/components/zhVideo.vue";
// 引入插件
//import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
export default {
  data() {
    return {
      indexs: 0, // 视频索引
      videoDataUrl: "",
      pageNum: 1,
      pageSize: 4,
      gameLogData: "", // 课堂分页数据
      gameAllLogData: "", // 全部数据
      swiperOption: {
        loop: true,
        // autoplay: {
        // delay: 3000,
        // stopOnLastSlide: false,
        // disableOnInteraction: false
        // },
        // 显示分页
        // pagination: {
        //   el: ".swiper-pagination",
        //   clickable: true //允许分页点击跳转
        // },
        // 设置点击箭头
        navigation: {
          nextEl: ".button-next",
          prevEl: ".button-prev",
        },
      },
      videoWidth: "100%",
      videoHeight: "100%",
      classList: [
        {
          url: "73b31500b7df470aa14090cf49c7a9ab",
          img: "https://bbpimg.ikicker.cn/child_list3.jpg",
          type: "1",
          title: "足球，让孩子掌握一门快乐的技能",
        },
        {
          url: "",
          img: "https://bbpimg.ikicker.cn/child_list2.jpg",
          type: "2",
          title: "运动，让孩子收获一生的健康",
        },
        {
          url: "",
          img: "https://bbpimg.ikicker.cn/child_list1.jpg",
          type: "3",
          title: "体育，让孩子拥有坚韧的品格",
        },
        {
          url: "",
          img: "https://bbpimg.ikicker.cn/child_list4.jpg",
          type: "4",
          title: "团队，让孩子成为合格的小公民",
        },
      ],
      unnormalUrl: {
        // 默认课程
        url: "fb3edb62452947fb8d056186fa0cbdb2",
        img: "https://pics2.baidu.com/feed/3bf33a87e950352ab60618d42cab93fbb0118bfc.png?token=2ad2bed8b7964834811845ef28ae19b2",
        type: "5",
        title: "111111111111111111111",
      },
      rankingList: [], // 挑战排行榜
      stuList: [], // 学生列表
    };
  },
  components: {
    vueSeamlessScroll,
    //videoPlayer,
    //swiper,
    // swiperSlide,
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    for (let i = 0; i < 20; i++) {
      let j = {
        title: "无缝滚动第几条啊啊啊-" + i,
        time: "2020-04-10",
      };
      this.rankingList.push(j);
      this.stuList.push(j);
    }
    this.init();
  },
  methods: {
    init() {
      this.getgameLogList();
      this.getAllgameLogList();
    },
    bindCutVideo(videoUrl, index) {
      this.videoDataUrl = videoUrl;
      console.log(videoUrl, "++===========", index);
      this.indexs = index;
    },
    getAllgameLogList() {
      // let data = {
      //   schoolId: this.$store.state.userInfo.schoolId,
      // };
      this.api.zhSchool.gameLogList().then((res) => {
        this.gameAllLogData = res.data.data;
      });
    },
    getgameLogList() {
      let data = {
        //schoolId: this.$store.state.userInfo.schoolId,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      };
      this.api.zhSchool.gameLogList(data).then((res) => {
        this.classList = res.data.data;
        this.gameLogData = res.data;
        this.videoDataUrl = res.data.data[0].videoUrl;
      });
    },
    // 上一页
    bindPrev() {
      this.pageNum -= 1;
      this.getgameLogList();
    },
    // 下一页
    biindNext() {
      this.pageNum += 1;
      this.getgameLogList();
    },
  },
  watch: {
    "unnormalUrl.url": {
      handler(newValue) {
        console.log(newValue, "切换这个视频");
      },
      immeidate: true,
    },
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    },
    plaqueOption() {
      return {
        step: 0.2, // 数值越大速度滚动越快
        limitMoveNum: this.stuList.length, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        // openWatch: true, // 开启数据实时监控刷新dom
        // singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    },
    stuOption() {
      return {
        step: 0.2, // 数值越大速度滚动越快
        limitMoveNum: this.rankingList.length, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 0, // 0向下 1向上 2向左 3向右
        // openWatch: true, // 开启数据实时监控刷新dom
        // singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/style/teaching/_handle.scss";
.teaching {
  width: 1535px;
  margin-left: 63px;
  display: flex;
  justify-content: space-between;
  .teaching-left {
    width: 955px;
    .teaching-left-top {
      @include flex(row, center, center);
      flex-direction: column;
      .teaching-left-top-box {
        width: 100%;
        height: 194px;
        background-image: url("../../../../assets/img/zhSchool/three/Pic_Bg_YEDT.png");
        background-size: cover;

        @include flex(row, center, center);
        .box-content {
          width: 90%;
          height: 140px;
          overflow: hidden;
        }
        .stu-content-list {
          height: 140px;
          display: flex;
          flex-flow: wrap;
          justify-content: space-between;
          overflow: hidden;
        }
        .stu-content-item {
          width: 100px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .stu-tx {
            width: 65px;
            height: 62px;
            background-image: url("../../../../assets/img/zhSchool/three/Pic_Zs_Tx_Yr.png");
            background-size: cover;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            img {
              width: 42px;
              height: 42px;
              opacity: 0.9;
              border-radius: 50%;
            }
          }
          .patriarch {
            p {
              width: 27px;
              height: 27px;
              background-image: url("../../../../assets/img/zhSchool/three/Pic_Bk_Tx_Jz.png");
              background-size: cover;
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column;
              img {
                width: 24px;
                height: 24px;
                border-radius: 24px;
              }
            }
          }
        }
      }
    }
    .teaching-left-bottom-box {
      width: 100%;
      height: 783px;
      @include background_bg("classLeftBg");
      background-size: cover;
      display: flex;
      align-items: center;
      flex-flow: column;
      .teaching-class-video-title {
        width: 723px;
        height: 15px;
        font-size: 22px;
        font-family: Source Han Sans SC;
        font-weight: 500;
        @include font_color("textColor");
        @include background_bg("classTitleBg");
        background-size: 100% 100%;
        margin: 0 auto;
        text-align: center;
        margin-top: 32px;
        margin-bottom: 15px;
      }
      .teaching-class-video-content {
        width: 862px;
        height: 481px;
        position: relative;
        @include background_bg("classVideoBg");
        background-size: 100% 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 30px;
        video {
          width: 862px;
          height: 481px;
        }
      }
      .teaching-class-test {
        width: 866px;
        height: 162px;
        @include background_bg("classTestBg");
        display: flex;
        align-items: center;
        margin-top: 20px;
        background-size: 100% 100%;
        .teaching-class-test-name {
          width: 188px;
          height: 117px;
          opacity: 0.7;
          border-radius: 4px;
          position: relative;
          margin-right: 20px;
          img {
            width: 188px;
            height: 117px;
            opacity: 0.7;
            border-radius: 6px;
          }
          p {
            width: 100%;
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            @include font_color("textColor");
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            position: absolute;
            bottom: 5px;
            box-sizing: border-box;
          }
        }
        .teaching-class-test-title {
          font-size: 20px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          @include font_color("textColor");
        }
        .teaching-class-test-content {
          width: 100%;
          display: flex;
          justify-content: space-between;
          box-sizing: border-box;
          .person {
            @include background_bg("classTestPerson");
          }
          .second {
            @include background_bg("classTestSecond");
          }
          .grade {
            @include background_bg("classTestGrade");
          }
          .teaching-class-test-list-item {
            width: 206px;
            height: 101px;
            background-size: 100% 100%;
            padding: 12px 0 0 14px;
            .item-title {
              font-size: 16px;
              @include font_color("classNameColor");
            }
            .item-num {
              font-size: 16px;
              @include font_color("classNameColor");
              span {
                font-size: 40px;
                font-weight: 400;
                font-family: Impact;
              }
            }
          }
        }

        .teaching-class-test-list-right {
          width: 128px;
          display: flex;
          ul {
            li {
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #c3e8ff;
              display: flex;
              justify-content: space-between;
              span {
                text-align: right;
              }
            }
          }
        }
      }
    }
    .teaching-left-tab {
      width: 100%;
      height: 245px;
      @include background_bg("tabContentBg");
      background-size: 100% 100%;
      padding: 0 35px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      flex-direction: column;
      .teaching-left-tab-title {
        width: 195px;
        height: 27px;
        @include background_bg("tabVideoBg");
        background-size: 100% 100%;
        font-size: 22px;
        font-weight: 500;
        @include font_color("textColor");
        text-align: center;
        line-height: 27px;
        margin-top: 26px;
        margin-bottom: 20px;
        display: inline-block;
      }
      .swiper-container {
        width: 835px;
      }
      .teaching-left-tab-box {
        display: flex;
        align-items: center;
      }
      .swiper-slide {
        display: flex;
        .class-list:first-child {
          margin-left: 0;
        }
        .class-list {
          width: 200px;
          height: 99px;
          border: 1px solid #c1eeff;
          border-radius: 6px;
          margin-left: 9px;
          video {
            width: 200px;
            height: 97px;
            opacity: 0.7;
            border-radius: 6px;
            cursor: pointer;
          }
          video::-webkit-media-controls {
            display: none !important;
          }
          p {
            font-size: 16px;
            @include font_color("classNameColor");
          }
        }
      }
    }
  }
  .teaching-right {
    width: 553px;
    .teaching-right-top {
      @include flex(row, center, center);
      flex-direction: column;
      .teaching-right-top-box {
        width: 100%;
        height: 566px;
        @include background_bg("threatBg");
        background-size: 100% 100%;
        margin-top: -6px;
        .teaching-right-bottom-box-title {
          margin-top: 0.16rem;
          font-size: 0.22rem;
          text-align: center;
          @include font_color("textColor");
        }
        .ranking-item-name {
          margin-top: 10px;
          padding: 0 43px;
          ul {
            display: flex;
            justify-content: space-around;
            height: 50px;
            background: #6486a630;
            li {
              flex: 1;
              text-align: center;
              line-height: 50px;
              font-size: 20px;
              font-family: Microsoft YaHei;
              font-weight: bold;
              color: #c3e8ff;
            }
          }
        }
        .ranking-item-null {
          height: 430px;
          overflow: hidden;
          padding: 0 43px;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 140px;
            height: 191px;
          }
        }
        .ranking-item-content {
          height: 425px;
          overflow: hidden;
          padding: 0 43px;
          ul {
            display: flex;
            justify-content: space-around;
            height: 43px;
            li {
              flex: 1;
              text-align: center;
              line-height: 43px;
              font-size: 18px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #a3d3e3;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
          ul:nth-child(even) {
            background: #6486a630;
            li {
              color: #fff;
            }
          }
        }
      }
    }
    .teaching-right-bottom {
      @include flex(row, center, center);
      flex-direction: column;
      margin-top: 23px;
      .teaching-right-bottom-box {
        width: 100%;
        height: 422px;
        @include background_bg("threatBottonBg");
        background-size: 100% 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        .teaching-right-bottom-box-title {
          margin-top: 16px;
          font-size: 22px;
          @include font_color("textColor");
          text-align: center;
        }
        .teaching-right-bottom-box-null {
          width: 100%;
          height: 422px;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 140px;
            height: 191px;
          }
        }
        .teaching-right-bottom-box-list {
          width: 475px;
          height: 86px;
          display: flex;
          @include background_bg("threatListBg");
          background-size: 100% 100%;
          margin-top: 20px;
          .teaching-right-bottom-box-list-img {
            width: 140px;
            height: 86px;
            background: rgba(0, 0, 0, 0);
            @include border("borderColor");
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 6px;
            video {
              width: 140px;
              height: 86px;
              opacity: 0.7;
              border-radius: 6px;
            }
            video::-webkit-media-controls {
              display: none !important;
            }
          }
        }
        .teaching-right-bottom-box-list-info {
          width: 100%;
          display: flex;
          justify-content: space-between;
          padding: 0 15px;
          .info-content {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            flex: 1;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            .info-name {
              font-size: 18px;
              @include font_color("textColor");
            }
            .info-num {
              font-size: 18px;
              @include font_color("txtColor");
              span {
                font-size: 18px;
                @include font_color("numColor");
              }
            }
            .info-second {
              font-size: 18px;
              @include font_color("txtColor");
              span {
                font-size: 18px;
                @include font_color("numColor");
              }
            }
          }
          .info-grade {
            display: flex;
            align-items: center;
            margin-right: 10px;
            .info-grade-content {
              display: flex;
              flex-direction: column;
              align-content: flex-end;
            }
            span {
              display: block;
            }
            .num {
              font-size: 28px;
              font-family: Microsoft YaHei;
              font-weight: bold;
              @include font_color("classGradeColor");
            }
            .text {
              font-size: 16px;
              @include font_color("numColor");
            }
          }
        }
      }
    }
  }
}
.school-info-title {
  width: 313px;
  height: 52px;
  background: url("../../../../assets/img/zhSchool/three/Pic_Bg_YEDT_TITLE.png")
    no-repeat;
  background-size: 100% 100%;
  font-size: 22px;
  font-weight: 500;
  color: #c3e8ff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.button-prev {
  width: 23px;
  height: 80px;
  @include background_bg("ArrayLeft");
  background-size: 100% 100%;
}
.button-next {
  width: 23px;
  height: 80px;
  @include background_bg("ArrayRight");
  background-size: 100% 100%;
}
</style>