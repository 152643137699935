<template>
  <div class="zh-school">
    <dv-full-screen-container class="zh-bg">
      <video
        class="video-background"
        preload="auto"
        loop=""
        playsinline=""
        autoplay=""
        src="../../../../assets/img/zhSchool/one/pic_bg.mp4"
        tabindex="-1"
        muted="muted"
      ></video>
      <dv-loading v-if="loading">加载中...</dv-loading>
      <div class="zh-school-box">
        <div class="zh-school-title">
          <div class="zh-school-name">
            {{ $store.state.schoolInfo.atteSchool.schoolName }}
          </div>
          <div class="zh-school-btn">
            <div
              :class="isFullscreen ? 'full-botton' : 'min-botton'"
              @click.stop="clickFullscreen"
            ></div>
            <div
              class="cloce-botton"
              @click="bindCOde"
            ></div>
          </div>
        </div>
        <div class="zh-school-con">
          <div class="zh-school-con-menu">
            <div
              class="menu-list"
              v-for="(item, index) in menu"
              :key="index"
              :class="'active_' + index"
              @click="gotab(item)"
            >
              <!-- <router-link :to="item.url">
                <span>{{ item.name }}</span>
              </router-link> -->
              <a :class="goindex==index+1?'router-link-active':''">
                <span>{{ item.name }}</span>
              </a>

            </div>
          </div>
          <div class="zh-school-con-detai">
            <!-- <router-view></router-view> -->
            <General v-if="goindex==1"></General>
            <Ranking v-if="goindex==2"></Ranking>
            <Teaching v-if="goindex==3"></Teaching>
            <Drill v-if="goindex==4"></Drill>
            <Game v-if="goindex==5"></Game>
            <settngs
              v-if="goindex==6"
              @skinpeeler="skinpeeler"
            ></settngs>
          </div>
        </div>
      </div>
      <popUp
        @bindSet="setBillDetail"
        @bindSkip="setBillSkip"
        v-if="isRoute"
      />
    </dv-full-screen-container>
    <!-- 海洋模式 -->
  </div>
</template>
<script>
import screenfull from "screenfull"; // 引入大屏插件
import popUp from "../../components/popup/popQue.vue";
//import popCof from "./components/popup/popCof.vue";
// import setTing from "./routeCon/config.vue";
import General from "../../routeCon/general.vue";
import Ranking from "../../routeCon/ranking.vue";
import Teaching from "../../routeCon/teaching.vue";
import Drill from "../../routeCon/drill.vue";
import Game from "../../routeCon/game.vue";
import settngs from "../../routeCon/config.vue";
export default {
  props: {
    isSet: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      isFullscreen: true, // 判断大屏显示
      isbox: true, // 判断模块
      isConfig: "OLDUSER", // 非智慧园所NORIGHT,//首次使用NEWUSER,//老用户OLDUSER
      isRoute: this.$store.state.authorityStatus, //判断跳转设置或者跳过
      loading: true,
      menu: [
        {
          name: "园所动态",
          url: "/zh/general",
          type: "1",
        },
        {
          name: "数据管理",
          url: "/zh/ranking",
          type: "2",
        },
        {
          name: "互动教学",
          url: "/zh/teaching",
          type: "3",
        },
        {
          name: "成长训练",
          url: "/zh/drill",
          type: "4",
        },
        {
          name: "比赛闯关",
          url: "/zh/game",
          type: "5",
        },
        {
          name: "设置管理",
          url: "/zh/settingsMange",
          type: "6",
        },
      ],
      goindex: 1,
    };
  },
  methods: {
    skinpeeler() {
      this.goindex = 1;
      this.$emit("skinpeeler");
    },
    gotab(item) {
      const list =
        (localStorage.getItem("userInfo") &&
          JSON.parse(localStorage.getItem("userInfo")).resourceUrls) ||
        [];
      console.log(list);
      for (var i = 0; i < list.length; i++) {
        if (item.url == list[i].url) {
          if (!list[i].flag) {
            this.isRoute = true;

            return false;
          }
        }
      }
      this.goindex = item.type;
    },
    checkSchoolStatus() {
      this.api.zhSchool
        .checkSchoolStatus(this.$store.state.userInfo.schoolId)
        .then((res) => {
          console.log(res, "111111111111111111");
          this.isConfig = res.data;
        });
    },
    cancelLoading() {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    },
    setBillDetail() {
      this.isRoute = false;
    },
    setBillSkip() {
      this.isbox = false;
    },
    bindIsSet() {
      this.isbox = false;
    },
    // 开启大屏
    clickFullscreen() {
      console.log(screenfull.isFullscreen);
      if (!screenfull.isEnabled) {
        // 此时全屏不可用
        this.$message.warning("此时全屏组件不可用");
        return;
      }
      this.isFullscreen = screenfull.isFullscreen;
      screenfull.toggle();
    },
    checkFull() {
      let isFull =
        document.fullscreenEnabled ||
        window.fullScreen ||
        document.webkitIsFullScreen ||
        document.msFullscreenEnabled;
      console.log(isFull);
      if (isFull == undefined) {
        isFull = false;
      }
      return isFull;
    },
    bindCOde() {
      this.$router.push({
        path: "/school/home",
      });
    },
  },
  mounted() {
    this.cancelLoading();
    // 记载默认主题颜色
    window.document.documentElement.setAttribute("data-theme", "base");
    //this.checkSchoolStatus(); // 判断是否示范园
    if (screenfull.enabled) {
      screenfull.on("change", this.change);
    }
    window.onresize = () => {
      if (!this.checkFull) {
        this.isFullscreen = false;
      }
    };
    // var divEle = document.getElementsByClassName("eimWbZ"); //获取标签
    // //var divStyle = divEle.style; //获取行内样式信息
    // divEle.style.display = "none"; //style.样式名=样式值;
    // console.log(divEle, "111111111111111111");
  },
  components: {
    popUp,
    General,
    Ranking,
    Teaching,
    Drill,
    Game,
    settngs,
    //popCof,
    //setTing,
  },
  watch: {
    "$store.state.authorityStatus": {
      handler(val) {
        console.log(val, 22222222222222222);
      },
      deep: true,
    },
  },
};
</script>


<style lang="scss" scoped>
@import "@/assets/style/_handle.scss";
.common-util {
  font-size: 18px;
  @include font_color("mainColor");
  @include background_bg("mainColor");
  @include border_color("mainColor");
}
.zh-school-con-detai {
  flex: 1;
  display: flex;
  justify-content: center;
}
.zh-school {
  color: #d3d6dd;
  background-color: #000000;
  width: 100%;
  height: 100%;

  .zh-bg {
    // @include background_bg("bgImage");
    // background-size: 100% 100%;
    // background-position: center center;
    position: relative;
    height: 100vh;
    overflow: hidden;
    .video-background {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      object-fit: cover;
      min-height: 800px;
    }
    .zh-school-box {
      @include background_bg("bgImage");
      background-size: 100% 100%;
      background-position: center center;
      width: 100%;
      height: 100%;
      // position: relative;
      position: absolute;
      .zh-school-title {
        width: 100%;
        height: 70px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-right: 30px;
        box-sizing: border-box;
        .zh-school-name {
          width: 411px;
          height: 70px;
          @include background_bg("schoolbgImage");
          background-size: 100% 100%;
          font-size: 30px;
          font-weight: 400;
          @include font_color("schoolColor");
          line-height: 71px;
          padding-left: 30px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .zh-school-btn {
          display: flex;
        }
        .full-botton {
          width: 46px;
          height: 46px;
          @include background_bg("QPBtnBg");
          background-size: 100% 100%;
          cursor: pointer;
        }
        .min-botton {
          width: 46px;
          height: 46px;
          @include background_bg("TCQPBtnBg");
          background-size: 100% 100%;
          cursor: pointer;
        }
        .cloce-botton {
          width: 46px;
          height: 46px;
          @include background_bg("closeBtnBg");
          background-size: 100% 100%;
          cursor: pointer;
          margin-left: 30px;
        }
      }
      .zh-school-con {
        display: flex;
        width: 100%;
        height: 100%;
        .zh-school-con-menu {
          margin-top: 230px;
          margin-left: 54px;
          .menu-list:first-child {
            padding-top: 0;
          }
          .active_0 a {
            @include background_bg("menuImage0");
            background-size: cover;
            cursor: pointer;
          }
          .active_1 a {
            @include background_bg("menuImage1");
            background-size: cover;
            cursor: pointer;
            span {
              margin-left: 20px;
            }
          }
          .active_2 a {
            @include background_bg("menuImage2");
            background-size: cover;
            cursor: pointer;
            span {
              margin-left: 25px;
            }
          }
          .active_3 a {
            @include background_bg("menuImage3");
            background-size: cover;
            cursor: pointer;
            span {
              margin-left: 25px;
            }
          }
          .active_4 a {
            @include background_bg("menuImage4");
            background-size: cover;
            cursor: pointer;
            span {
              margin-left: 20px;
            }
          }
          .active_5 a {
            @include background_bg("menuImage5");
            background-size: cover;
            cursor: pointer;
          }
          .active_0 .router-link-active {
            @include background_bg("menuActiveImage0");
            @include font_color("menuActiveColor");
            background-size: cover;
          }
          .active_1 .router-link-active {
            @include background_bg("menuActiveImage1");
            @include font_color("menuActiveColor");
            background-size: cover;
          }
          .active_2 .router-link-active {
            @include background_bg("menuActiveImage2");
            @include font_color("menuActiveColor");
            background-size: cover;
          }
          .active_3 .router-link-active {
            @include background_bg("menuActiveImage3");
            @include font_color("menuActiveColor");
            background-size: cover;
          }
          .active_4 .router-link-active {
            @include background_bg("menuActiveImage4");
            @include font_color("menuActiveColor");
            background-size: cover;
          }
          .active_5 .router-link-active {
            @include background_bg("menuActiveImage5");
            @include font_color("menuActiveColor");
            background-size: cover;
          }
          .menu-list {
            width: 234px;
            height: 68px;
            padding-top: 18px;
            a {
              display: block;
              width: 234px;
              height: 68px;
              font-size: 22px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              @include font_color("schoolColor");
              text-align: center;
              line-height: 68px;
            }
          }
        }
      }
    }
  }
}
</style>
