<!--
 * @Descripttion: 今天，你进步了吗？
 * @version: V1.0
 * @Author: lily
 * @Date: 2022-05-02 14:24:00
 * @LastEditors: lily
 * @LastEditTime: 2022-05-09 14:15:29
-->
<template>
  <div class="teaching">
    <component :is="currentRole" />
  </div>
</template>
<script>
// 海洋模式
import oceanTeaching from "../html/ocean/oceanTeaching.vue";
// 星空模式
import starryTeaching from "../html/starry/starryTeaching.vue";
export default {
  components: {
    oceanTeaching,
    starryTeaching,
  },
  data() {
    return {
      currentRole: "",
    };
  },
  computed: {
    // swiper() {
    //   return this.$refs.mySwiper.swiper;
    // },
  },
  mounted() {
    // 获取默认智慧风格
    this.getStyle();
  },
  methods: {
    getStyle() {
      this.api.zhSchool
        .schoolDetail(this.$store.state.userInfo.schoolId)
        .then((res) => {
          if (res.errCode == 0) {
            if (res.data.wisdomScreenStyleId == 1) {
              this.currentRole = "starryTeaching";
            } else if (res.data.wisdomScreenStyleId == 2) {
              this.currentRole = "oceanTeaching";
            }else{
                this.currentRole = "starryTeaching";
            }
          }
        });
    },
  },
};
</script>
<style>
.teaching{
width:100%;}
</style>
