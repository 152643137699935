<template>
  <div class="zh-school">
    <!-- <component :is="currentRole" /> -->
    <OceanIndex v-if="currentRole==2"  @skinpeeler="skinpeeler"></OceanIndex>
    <StarryIndex v-if="currentRole==1" @skinpeeler="skinpeeler"></StarryIndex>
  </div>
</template>
<script>
// 海洋模式
import OceanIndex from "./html/ocean/index.vue";
// 星空模式
import StarryIndex from "./html/starry/index.vue";
export default {
  props: {
    isSet: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      currentRole: 1, // 默认组件展示

      isFullscreen: true, // 判断大屏显示
      isbox: true, // 判断模块
      isConfig: "OLDUSER", // 非智慧园所NORIGHT,//首次使用NEWUSER,//老用户OLDUSER
      isRoute: this.$store.state.authorityStatus, //判断跳转设置或者跳过
      loading: true,
      menu: [
        {
          name: "园所动态",
          url: "general",
          type: "1",
        },
        {
          name: "数据管理",
          url: "ranking",
          type: "2",
        },
        {
          name: "互动教学",
          url: "teaching",
          type: "3",
        },
        {
          name: "成长训练",
          url: "drill",
          type: "4",
        },
        {
          name: "比赛闯关",
          url: "game",
          type: "5",
        },
        {
          name: "设置管理",
          url: "settngs",
          type: "6",
        },
      ],
    };
  },
  methods: {
    getStyle() {
      this.api.zhSchool
        .schoolDetail(this.$store.state.userInfo.schoolId)
        .then((res) => {
          if (res.errCode == 0) {
            if (res.data.wisdomScreenStyleId == 1) {
              this.currentRole = res.data.wisdomScreenStyleId;
              
            } else if (res.data.wisdomScreenStyleId == 2) {
              this.currentRole = res.data.wisdomScreenStyleId;
            }else{
              this.currentRole = 1;
            }
          }
          console.log('res',  this.currentRole);
        });
    },
    skinpeeler(){
      this.getStyle()
    }
  },
  mounted() {
    this.getStyle();
    console.log("3333")
    // var divEle = document.getElementsByClassName("eimWbZ"); //获取标签
    // //var divStyle = divEle.style; //获取行内样式信息
    // divEle.style.display = "none"; //style.样式名=样式值;
    // console.log(divEle, "111111111111111111");
  },
  components: {
    OceanIndex,
    StarryIndex,
  },
  watch: {
    "$store.state.skinpeeler": {
      handler(val) {
        console.log(val, 22222222222222222);
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss">
  #meiqia-container{
  display: none !important;}
</style>



