<template>
  <div class="zh-school">
    <dv-full-screen-container class="zh-bg">
      <video
        class="video-background"
        preload="auto"
        loop=""
        playsinline=""
        autoplay=""
        src="https://footballcn.oss-cn-beijing.aliyuncs.com/wisdom1649642704211.mp4"
        tabindex="-1"
        muted="muted"
      ></video>
      <dv-loading v-if="loading">加载中...</dv-loading>
      <div class="zh-school-box">
        <div class="zh-school-title">
          <div class="zh-school-name">
            <h2>{{ $store.state.schoolInfo.atteSchool.schoolName }}</h2>
            <dv-decoration-2 style="width: 100%; height: 5px" />
          </div>
          <div class="zh-school-btn">
            <div
              :class="isFullscreen ? 'full-botton' : 'min-botton'"
              @click.stop="clickFullscreen"
            ></div>
            <div
              class="cloce-botton"
              @click="bindCOde"
            ></div>
          </div>
        </div>

        <div
          class="zh-school-con"
          :style="{'height':Wheight+'px'}"
        >
          <div class="zh-school-con-menu">
            <div
              class="menu-list"
              v-for="(item, index) in menu"
              :key="index"
              :class="'active_' + index"
              @click="gotab(item)"
            >
              <!-- <router-link :to="item.url">
                <span>{{ item.name }}</span>
                <dv-decoration-10
                  style="width: 100%; height: 2px"
                  :color="['#ffffff']"
                />
              </router-link> -->
              <a :class="goindex==index+1?'router-link-active':''">
                <span>{{ item.name }}</span>
                <dv-decoration-10
                  style="width: 100%; height: 2px"
                  :color="['#ffffff']"
                />
              </a>
            </div>
          </div>
          <div class="zh-school-con-detai">
            <!-- <router-view></router-view> -->
            <General v-if="goindex==1"></General>
            <Ranking v-if="goindex==2"></Ranking>
            <Teaching v-if="goindex==3"></Teaching>
            <Drill v-if="goindex==4"></Drill>
            <Game v-if="goindex==5"></Game>
            <settngs
              v-if="goindex==6"
              @skinpeeler="skinpeeler"
            ></settngs>
          </div>
        </div>
      </div>
      <popUp
        @bindSet="setBillDetail"
        @bindSkip="setBillSkip"
        v-if="isRoute"
      />
    </dv-full-screen-container>
    <!-- 海洋模式 -->
  </div>
</template>
<script>
import screenfull from "screenfull"; // 引入大屏插件
import popUp from "../../components/popup/popQue.vue";
//import popCof from "./components/popup/popCof.vue";
// import setTing from "./routeCon/config.vue";
import General from "../../routeCon/general.vue";
import Ranking from "../../routeCon/ranking.vue";
import Teaching from "../../routeCon/teaching.vue";
import Drill from "../../routeCon/drill.vue";
import Game from "../../routeCon/game.vue";
import settngs from "../../routeCon/config.vue";
export default {
  props: {
    isSet: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isFullscreen: true, // 判断大屏显示
      isbox: true, // 判断模块
      isConfig: "OLDUSER", // 非智慧园所NORIGHT,//首次使用NEWUSER,//老用户OLDUSER
      isRoute: this.$store.state.authorityStatus, //判断跳转设置或者跳过
      loading: true,
      menu: [
        {
          name: "园所动态",
          url: "/zh/general",
          type: "1",
          
        },
        {
          name: "数据管理",
          url: "/zh/ranking",
          type: "2",
          
        },
        {
          name: "互动教学",
          url: "/zh/teaching",
          type: "3",
        },
        {
          name: "成长训练",
          url: "/zh/drill",
          type: "4",
        },
        {
          name: "比赛闯关",
          url: "/zh/game",
          type: "5",
        },
        {
          name: "设置管理",
          url: "/zh/settingsMange",
          type: "6",
        },
      ],
      goindex: 1,
      Wheight: window.innerHeight - 50,
    };
  },
  methods: {
    skinpeeler() {
      this.goindex = 1;
      this.$emit("skinpeeler");
    },
    gotab(tiem) {
     
      
      const list = localStorage.getItem('userInfo') && JSON.parse(localStorage.getItem('userInfo')).resourceUrls || [];
       console.log(list)
      for(var i=0; i<list.length;i++){
        if(tiem.url==list[i].url){
          console.log(list[i].flag)
          if(!list[i].flag){
            this.isRoute = true;
          
            return  false
            
          }
    }
    
    }
      this.goindex = tiem.type;
    },
    checkSchoolStatus() {
      this.api.zhSchool
        .checkSchoolStatus(this.$store.state.userInfo.schoolId)
        .then((res) => {
          console.log(res, "111111111111111111");
          this.isConfig = res.data;
        });
    },
    cancelLoading() {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    },
    setBillDetail() {
      this.isRoute = false;
    },
    setBillSkip() {
      this.isbox = false;
    },
    bindIsSet() {
      this.isbox = false;
    },
    // 开启大屏
    clickFullscreen() {
      console.log(screenfull.isFullscreen);
      if (!screenfull.isEnabled) {
        // 此时全屏不可用
        this.$message.warning("此时全屏组件不可用");
        return;
      }
      this.isFullscreen = screenfull.isFullscreen;

       
      screenfull.toggle();
      // this.Wheight=window.innerHeight-70;
      //  console.log(this.Wheight,'this.Wheightthis.Wheightthis.Wheight')
    },
    // 将窗口高度赋值给节点
    changeFixed(clienHright) {
      this.Wheight = clienHright + "px";
    },
    checkFull() {
      let isFull =
        document.fullscreenEnabled ||
        window.fullScreen ||
        document.webkitIsFullScreen ||
        document.msFullscreenEnabled;
      console.log(isFull);
      if (isFull == undefined) {
        isFull = false;
      }
      return isFull;
    },
    bindCOde() {
      this.$router.push({
        path: "/school/home",
      });
    },
  },
  mounted() {
    this.cancelLoading();

    // 记载默认主题颜色
    window.document.documentElement.setAttribute("data-theme", "base");
    //this.checkSchoolStatus(); // 判断是否示范园
    if (screenfull.enabled) {
      screenfull.on("change", this.change);
    }
    
    window.addEventListener('resize',()=>{
          
           
            if (!this.checkFull) {
              this.isFullscreen = false;
            }
            this.Wheight =  window.innerHeight - 50
            console.log('this.Wheight')
           console.log(this.Wheight)
    })
    // var divEle = document.getElementsByClassName("eimWbZ"); //获取标签
    // //var divStyle = divEle.style; //获取行内样式信息
    // divEle.style.display = "none"; //style.样式名=样式值;
    // console.log(divEle, "111111111111111111");
  },
  components: {
    popUp,
    General,
    Ranking,
    Teaching,
    Drill,
    Game,
    settngs,
    //popCof,
    //setTing,
  },
  watch: {
    "$store.state.authorityStatus": {
      handler(val) {
        console.log(val, 22222222222222222);
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/style/_handle.scss";
.common-util {
  font-size: 18px;
  @include font_color("mainColor");
  @include background_bg("mainColor");
  @include border_color("mainColor");
}
.zh-school-con-detai {
  
  width:calc(100% - 286px);
  display: flex;
  justify-content: center;
  padding-bottom:50px;
  padding-right:30px;
  margin-left:286px;
  box-sizing: border-box;
}
.zh-school {
  color: #d3d6dd;
  width: 100%;
  height: 100%;

  .zh-bg {
    // @include background_bg("bgImage");
    // background-size: 100% 100%;
    // background-position: center center;
    position: relative;
    height: 100vh;
    overflow: hidden;
    .video-background {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      object-fit: cover;
      min-height: 800px;
    }
    .zh-school-box {
      width: 100%;
      height: calc(100vh);
      position: absolute;
      .zh-school-title {
        width: 100%;
        height: 70px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-right: 30px;
        box-sizing: border-box;
        .zh-school-name {
          font-size: 25px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #ffffff;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin-left: 50px;
          //border-bottom: 1px solid #ffffff;
          h2 {
            padding-bottom: 12px;
          }
        }
        .zh-school-btn {
          display: flex;
        }
        .full-botton {
          width: 46px;
          height: 46px;
          @include background_bg("QPBtnBg");
          background-size: 100% 100%;
          cursor: pointer;
        }
        .min-botton {
          width: 46px;
          height: 46px;
          @include background_bg("TCQPBtnBg");
          background-size: 100% 100%;
          cursor: pointer;
        }
        .cloce-botton {
          width: 46px;
          height: 46px;
          @include background_bg("closeBtnBg");
          background-size: 100% 100%;
          cursor: pointer;
          margin-left: 30px;
        }
      }
      .zh-school-con {
        display: flex;
        width: 100%;
       // margin-bottom: 50px;
        
        .zh-school-con-menu {
         
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          width:286px;
          box-sizing: border-box;
          position: absolute;
          left:0;
          height:100%;
          .menu-list:first-child {
            padding-top: 0;
          }
          .menu-list .router-link-active {
            color: #ffeacf;
          }
          .menu-list {
            padding-top: 18px;
            a {
              cursor: pointer;
              font-size: 22px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #5e637e;
              span {
                padding-bottom: 30px;
                display: block;
              }
            }
          }
        }
      }
    }
  }
}
</style>
