<!--
 * @Descripttion: 今天，你进步了吗？
 * @version: V1.0
 * @Author: lily
 * @Date: 2022-03-24 13:43:48
 * @LastEditors: lily
 * @LastEditTime: 2022-05-04 20:45:11
-->
<template>
  <div style="" class="game">
    <img src="../../../assets/img/zhSchool/Pic_BSCG_JT.png" />
  </div>
</template>
<script>
export default {
  mounted() {
    // 加载默认主题颜色
    //window.document.documentElement.setAttribute("data-theme", "base");
    // window.document.documentElement.setAttribute("data-theme", "base");
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/style/game/_handle.scss";
.game {
  width: 100%;
  height:100%;
  img {
    width: 100%;
    height:100%;
    object-fit: fill;
    object-position: left 0px bottom 5%;
  }
}
</style>